import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Careers from "./components/Careers";
import Home from "./components/Home";
import Media from "./components/Media";
import Policy from "./components/Policy";
import Contact from "./components/Contact";
import { useState } from "react";

function App() {
  
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);

  return (
    <div className="App">
      <div
        style={{ 
          minHeight: "100vh", maxWidth: "100vw",
          }} 
        className="flex w-screen  w-full pb-20 lg:pb-12  "
        
      >
        
        <Router>
          <Routes>
            <Route path="careers" element={<Careers />} />
            <Route path="media" element={<Media />} />
            <Route path="contact" element={<Contact loading={loading} setLoading={setLoading} loading2={loading2} setLoading2={setLoading2}/>} />
            <Route path="policy" element={<Policy />} />
            <Route path="/" element={<Home  loading={loading} setLoading={setLoading} loading2={loading2} setLoading2={setLoading2}/>} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
