import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const NavItem = ({ name = "", link = "", selected }) => {
  return (
    <Link
      to={link ? link : ""}
      className={
        selected
          ? "font-thin flex items-center justify-center text-sm md:border md:bg-black md:w-24 h-12 rounded-full my-2 md:p-1 text-primary cursor:pointer"
          : "font-thin flex items-center justify-center text-sm md:border md:bg-black md:w-24 h-12 rounded-full text-white my-2 md:p-1 hover:text-primary cursor:pointer"
      }
    >
      {name ? name : ""}
    </Link>
  );
};
export const LinkNav = ({ title, bg }) => {
  return (
    <div className={"h-20 flex items-center mt-5 justify-space-between w-4/5"}>
      <Link
        to="/"
        className={
          bg
            ? "cursor-pointer border text-black rounded-full lg:w-20 lg:h-20 w-12 h-12 flex items-center justify-center"
            : "cursor-pointer border-white border rounded-full lg:w-20 lg:h-20 w-12 h-12 flex items-center justify-center"
        }
      >
        <img
          src={bg ? "assets/arrow1.svg" : "assets/arrow2.svg"}
          alt="arrow"
          className="w-1/2 ml-2 lg:m-0"
        />
      </Link>
      <div className="w-1/2 flex justify-center items-center">
        <a
          className={
            bg
              ? "text-xs lg:text-sm font-thin border px-2  text-center rounded-full"
              : "text-xs lg:text-sm text-white font-thin border px-2 py-1 text-center rounded-full"
          }
        >
          Cloudabill
        </a>
        <a className={bg ? "text-xs lg:text-sm" : "text-xs lg:text-sm text-white"}>/{title}</a>
      </div>
    </div>
  );
};
function Navbar() {
  const location = useLocation();
  return (
    <div className=" w-4/5 md:w-10/12 h-10   md:h-24 w-full absolute md:static flex items-center justify-between  ">
      <NavItem name={"Careers"} link={"/careers"} />
      <NavItem name={"Media kit"} link={"/media"} />
      <NavItem
        name={"Get in touch"}
        link={"/contact"}
        selected={location.pathname === "/contact"}
      />
      <NavItem name={"Policy"} link={"/policy"} />
    </div>
  );
}
export default Navbar;
