import { saveAs } from "file-saver";
import { LinkNav } from "./Navbar";

function Media() {
  const downloadImage = (id) => {
    if (id === 0) {
      saveAs("assets/newLogoMark.png", "cloudabillLogoMark.png");
      saveAs("assets/newLogo.png", "cloudabillLogo.png");
    }
    if (id === 1) saveAs("assets/newLogo.png", "cloudabillLogo.png");
    if (id === 2) saveAs("assets/newLogoMark.png", "cloudabillLogoMark.png");
  };

  return (
    <div className="min-h-screen w-full absolute z-20 flex flex-col items-center justify-start overflow-y-auto">
    
      <LinkNav title={"Media kit"} />
      <div className="bg-black my-10 lg:my-auto lg:bg-bg w-full flex flex-col items-center h-40 ">
        <div className="flex flex-col items-start justify-center h-full w-4/5 lg:w-2/5 lg:ml-8">
          <h1 className="text-white lg:text-black font-thin text-xl lg:text-4xl py-3">
            Media Kit
          </h1>
          <p className="text-white lg:text-black text-xs lg:text-lg  text-left font-thin pb-3">
            Download Cloudabill’s latest press assets. The animated logo is our
            primary logo — please use the provided MP4 or GIF whenever possible.
          </p>
        </div>
      </div>
      <div
        className="media  flex items-center flex-col rounded-3xl lg:flex-row justify-center lg:justify-start h-56 clip"
        style={{
          background:
            "transparent linear-gradient(119deg, #FFFFFF66 0%, #B6FF9BCC 100%) 0% 0% no-repeat padding-box",
          boxShadow: "inset 0px 0px 80px #FFFFFF",
          opacity: 1,
          backdropFilter: "blur(20px)",
          WebkitBackdropFilter: "blur(20px)",
                 }}
      >
        <div className="flex w-full h-full items-center justify-center lg:w-3/4">
          <div
            onClick={() => downloadImage(1)}
            className="w-1/2 lg:w-1/2 h-4/5 lg:h-52 flex flex-col items-center justify-center border border-white rounded-2xl mr-2 ml-2"
          >
            <div className="h-1/2 flex items-end">
              <img src="assets/newLogo.png" alt="logo" className="w-32" />
            </div>
            <div className="w-5/6 flex justify-between h-1/3  mt-3 items-end">
              <p className=" font-thin text-sm">Original logo</p>
              <img
                src="assets/downloadLight.svg"
                alt="download"
                className="cursor-pointer w-5 lg:w-6"
              />
            </div>
          </div>
          <div
            onClick={() => downloadImage(2)}
            className="w-1/2 lg:w-1/2 h-4/5 lg:h-52 flex flex-col items-center justify-center border border-white rounded-2xl mr-2 "
          >
            <div className="h-1/2 flex items-end">
              <img src="assets/newLogoMark.png" alt="logo" className="w-12" />
            </div>
            <div className="w-5/6 flex justify-between h-1/3  mt-3 items-end">
              <p className="font-thin text-sm">Logo mark</p>
              <img
                src="assets/downloadLight.svg"
                alt="download"
                className="cursor-pointer w-5 lg:w-6"
              />
            </div>
          </div>
        </div>
        <div className=" w-full lg:w-1/4 flex items-end justify-end mr-5 lg:justify-center lg:h-full">
          {" "}
          <a
            onClick={() => downloadImage(0)}
            className="mr-2 text-sm mb-5 ml-3 cursor-pointer flex items-center"
          >
            Download all
            <img
              src="assets/Download.svg"
              alt="Download"
              className="w-6 ml-2"
            />
          </a>
        </div>
      </div>
      <div className="bg-black mt-10 lg:mt-auto lg:bg-bg flex items-center justify-center h-60 lg:h-1/4 w-full ">
        <p className="text-left text-white text-xs lg:text-lg lg:text-black  lg:text-block font-thin w-4/5 lg:w-2/5 lg:ml-8">
          You may use these Cloudabill logos and assets only for editorial news
          distribution. By downloading these assets, you agree to use the
          animated logo instead of the static logo whenever possible. For
          non-editorial uses, please request permission at press@cloudabill.com.
        </p>
      </div>
    </div>
  );
}
export default Media;
