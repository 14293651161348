import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyARkN-BH7PTK74_oICToHj0WLHi7BOC3oU",
  authDomain: "cloudbill-1e61b.firebaseapp.com",
  projectId: "cloudbill-1e61b",
  storageBucket: "cloudbill-1e61b.appspot.com",
  messagingSenderId: "1013817997398",
  appId: "1:1013817997398:web:339543975bed538d9ba3f7",
  measurementId: "G-FBT7NX4S9D",
};

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}    
// Initialize Cloud Firestore and get a reference to the service
export const db = firebase.firestore();
export const auth = firebase.auth();

export const createMessage = async (email, name, message) => {
    try{
  await  firebase.auth().signInAnonymously()
  var messageRef = db.collection("messages").doc(email);
  
  messageRef.set(
    {
      name,
      email,
      message,
    },
    { merge: true }
  );}
  catch(e){
      console.log(e);
  }
};
