import { useState } from "react";
import { LinkNav } from "./Navbar";
import useSWR from "swr";

const CareerCard = (id, ref, industry, remote, country, city) => {
  return (
    <a
      key={id}
      className="rounded-full py-1 lg:h-20 border border-bg flex items-center justify-start lg:justify-between mb-5 lg:px-2 cursor-pointer"
      href={ref}
    >
      <div className="flex items-center h-full justify-between w-4/5">
        <img src="assets/ball3D.png" className="w-16 ml-1 lg:ml-0" />
        <div className="flex flex-col justify-between items-center h-4/5 w-3/5 lg:flex-row">
          <p className="font-bold lg:text-xl text-bg italic">{industry}</p>
          <div className="flex justify-between items-center lg:w-1/2">
            <small className="text-bg lg:text-lg font-thin mr-2">
              FullTime
            </small>
            <small className="text-bg lg:text-lg font-thin">
              {city.slice(0, city.indexOf(" "))}, {country.toUpperCase()}
            </small>
          </div>
        </div>
      </div>
      {remote && (
        <small className="bg-sec rounded-3xl px-4 py-2 text-lg font-thin hidden lg:block mr-2">
          Remote
        </small>
      )}
    </a>
  );
};
const filters = ["All"];
function Careers() {
  const [selected, setSelected] = useState(0);
  const [search, setSearch] = useState("");

  const fetcher = (...args) => fetch(...args).then((res) => res.json());
  const { data, error } = useSWR(
    "https://api.smartrecruiters.com/v1/companies/Cloudabill/postings/?limit=10",
    fetcher
  );

  const renderCareers = () => {
    if (error) return <div>failed to load</div>;
    if (!data) return <div>loading...</div>;
    if (data) {
      return data.content
        .filter((i) => selected === 0 || i.industry.label === filters[selected])
        .filter(
          (i) =>
            search.length === 0 ||
            i.name.toLowerCase().indexOf(search.toLowerCase()) != -1
        )
        .map((posting, index) => {
          if (filters.indexOf(posting.industry.label) < 0)
            filters.push(posting.industry.label);
          let ref = "https://jobs.smartrecruiters.com/Cloudabill/" + posting.id;
          return (
            <div key={index}>
              {CareerCard(
                posting.id,
                ref,
                posting.name,
                posting.location.remote,
                posting.location.country,
                posting.location.city
              )}
            </div>
          );
        });
    }
  };

  const FilterCard = (title, hasNext) => {
    return (
      <a
        onClick={() => setSelected(filters.indexOf(title))}
        className={
          hasNext
            ? "flex items-center justify-start p-3 font-thin text-bg"
            : "flex items-center justify-start p-3 font-thin text-bg border-b "
        }
      >
        {filters[selected] === title ? (
          <div className="rounded-full w-10 h-10 border border-bg mr-3 bg-primary"></div>
        ) : (
          <div className="rounded-full w-10 h-10 border border-bg mr-3"></div>
        )}
        {title}
      </a>
    );
  };

  return (
    <div className="flex flex-col w-full h-screen absolute z-10  overflow-y-auto">
      <div className="bg-bg w-full flex justify-center items-center h-40 pb-5  ">
        {" "}
        <LinkNav title={"Careers"} bg />
      </div>
      <div className="h-32 flex justify-center items-center">
        <p style={{minHeight:100}} className="text-primary text-4xl lg:text-5xl font-thin lg:w-1/3 lg:text-left flex items-center">
          Join us, Be you.
        </p>
      </div>
      <div style={{minHeight:150}} className=" bg-bg"></div>
      <div style={{minHeight:"100vh"}} className="h-full bg-black min-h-screen overflow-auto">
        <div className="h-28 border-b border-bg flex justify-center ">
          <div className="hidden lg:block w-56 "></div>
          <div className="w-5/6 lg:w-4/6 ">
            <input
              style={{ background: "transparent" }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search jobs"
              className="w-5/6 lg:w-4/6 text-bg font-thin h-1/2 lg:h-3/5 rounded-full border p-5 border-bg text-xl mt-4 lg:ml-4"
            />
          </div>
        </div>
        <div className="flex w-full justify-center items-start ">
          <div

            style={{ height: "fit-content" }}
            className="hidden lg:block w-56  mr-10 mt-10 border border-bg rounded-3xl"
          >
            {filters.map((item, index) => (
              <div key={index}>
                {FilterCard(item, index === filters.length - 1)}
              </div>
            ))}
          </div>
          <div className="w-5/6 lg:w-4/6 my-10">{renderCareers()}</div>
         
        </div>
      </div>
    </div>
  );
}
export default Careers;
