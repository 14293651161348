import { useState } from "react";
import SecDiv from "./SecDiv";
import { createMessage } from "../API";

const NavLeft = ({ setId, id }) => {
  const aStyleSelected =
    "border border-primary h-8 md:h-10 w-8 md:w-10 rounded-full p-1 scale-125 ";
  const aStyle =
    "border border-white h-8 md:h-10 w-8 md:w-10 rounded-full p-1 hover:scale-110 ";
  return (
    <div className="flex justify-between w-32 lg:w-40 ">
      <a
        onClick={() => setId(1)}
        className={id === 1 ? aStyleSelected : aStyle}
      >
        <img src="assets/icon3d1.png" alt="icon" />
      </a>
      <a
        onClick={() => setId(2)}
        className={id === 2 ? aStyleSelected : aStyle}
      >
        <img src="assets/icon3d2.png" className="lg:ml-1" alt="icon" />
      </a>
      <a
        onClick={() => setId(3)}
        className={id === 3 ? aStyleSelected : aStyle}
      >
        <img src="assets/icon3d3.png" className="mt-1" alt="icon" />
      </a>
    </div>
  );
};

const NavRight = ({ setId, id }) => {
  let next = () => setId(id === 1 ? 2 : id === 2 ? 3 : 1);
  let prev = () => setId(id === 1 ? 3 : id === 2 ? 1 : 2);
  return (
    <div className="flex justify-between w-24">
      <a
        onClick={prev}
        className="border h-6 w-10 rounded-full  flex items-center justify-center cursor-pointer hover:scale-110"
      >
        <img src="assets/arrow1.svg" alt="arrow" className="w-5 ml-2" />
      </a>
      <a
        onClick={next}
        className="border h-6 w-10 rounded-full  flex items-center justify-center cursor-pointer hover:scale-110"
      >
        <img
          src="assets/arrow1.svg"
          alt="arrow"
          className="w-5 ml-3 rotate-180"
        />
      </a>
    </div>
  );
};
const content1 = (id) => {
  return (
    <div>
      {id === 1 && (
        <>
          <h1 className="hidden xl:block text-white text-4xl text-left font-thin">
            A Paramount{" "}
            <span className="font-bold italic ">
              <b>Cloud</b>
            </span>
          </h1>
          <h1 className="xl:hidden text-white text-3xl text-left font-thin">
            A Paramount{" "}
            <span className="font-bold italic ">
              <b>Cloud</b>
            </span>
          </h1>
          <p className="text-left  xl:w-2/3 text-sm  my-5">
            Cloudabill is a collective of financial services companies that aims
            to redefine traditional banking through technology.
            <br />
            <br /> We build products that help make your lives easier. The
            company’s product portfolio includes Trustbill, a software solution
            that offers the next generation of digital corporate Payments, and
            Trusell, A retail management system.
          </p>
        </>
      )}
      {id === 2 && (
        <>
          <h1 className="hidden xl:block text-white text-4xl text-left font-thin">
            Methodology{" "}
          </h1>
          <h1 className="xl:hidden text-white text-3xl text-left font-thin">
            Methodology{" "}
          </h1>
          <p className="text-left lg:w-2/3 text-sm  my-5">
            At Cloudabill, we believe that establishing links, be it in <br />
            the form of connecting with the team or in welcoming <br />
            collaboration, strengthens our collective.{" "}
          </p>
        </>
      )}
      {id === 3 && (
        <>
          <h1 className="hidden lg:block text-white text-4xl text-left font-thin">
            The Team{" "}
          </h1>
          <h1 className="lg:hidden text-white text-3xl text-left font-thin">
            The Team{" "}
          </h1>

          <p className="text-left lg:w-2/3 text-sm  my-5">
            At Cloudabill, we believe that establishing links, be it in <br />
            the form of connecting with the team or in welcoming <br />
            collaboration, strengthens our collective.{" "}
          </p>
        </>
      )}
    </div>
  );
};
const defaultMessage =
  "Hi, We are very excited to hear from you; Also, Your message goes here";

function MainDiv({ contact }) {
  const [id, setId] = useState(1);
  const [mood, setMood] = useState(contact ? 3 : 0);
  const [name, setName] = useState("Name");
  const [email, setEmail] = useState("Email");
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);
  const [message, setMessage] = useState(defaultMessage);
  const checkValidEmail = (email) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      return true;
    }
    return false;
  };

  const OnSend = async () => {
    if (checkValidEmail(email)) {
      setError(false);
      if (!sent) await createMessage(email, name, message);
      setSent(true);
      setEmail("Email");
      setName("Name");
      setMessage(defaultMessage);
    } else {
      setError(true);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      className=" w-5/6 lg:w-11/12 h-full"
    >
      <div
        style={{
          background:
            "transparent linear-gradient(119deg, #FFFFFF66 0%, #B6FF9BCC 100%) 0% 0% no-repeat padding-box",
          boxShadow: "inset 0px 0px 80px #FFFFFF",
          borderRadius: "30px",
          opacity: 1,
          backdropFilter: "blur(20px)",
          WebkitBackdropFilter: "blur(20px)",
        }}
        className="w-full lg:h-96  bg-blend-multiply mt-8 lg:mt-3 rounded-3xl pt-7 px-7 flex flex-col justify-between mb-3 mainDiv"
      >
        {mood === 0 ? (
          <>
            {" "}
            {content1(id)}
            <div className="w-full flex justify-between items-center mb-3 lg:mb-5">
              <div className="">
                <NavLeft setId={setId} id={id} />
              </div>
              <NavRight setId={setId} id={id} />
            </div>
          </>
        ) : mood === 3 ? (
          <div className="flex flex-col items-start justify-center">
            <div className="w-full h-6 flex justify-start ">
              <a
                onClick={() => setMood(0)}
                className="w-10 h-6 border relative rounded-3xl flex items-center justify-center cursor-pointer"
              >
                <img src="assets/arrow1.svg" alt="arrow" className="w-1/2" />
              </a>
            </div>
            <textarea
              style={{ background: "transparent", overflow: "hidden" }}
              className="lg:text-2xl text-left mt-6 font-thin h-52 w-full focus:outline-none"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center">
            {" "}
            <div className="w-full h-6 flex justify-start -mt-3">
              <a
                onClick={() => setMood(0)}
                className="w-10 h-6 border relative rounded-3xl flex items-center justify-center cursor-pointer"
              >
                <img src="assets/arrow1.svg" alt="arrow" className="w-1/2" />
              </a>
            </div>
            <img
              alt="ball"
              src={mood === 1 ? "assets/ball23D.png" : "assets/ball3D.png"}
              className=" lg:w-2/5 mb-20"
            />
          </div>
        )}
      </div>
      <div className="w-full mb-8">
        <SecDiv
          setMood={setMood}
          mood={mood}
          email={email}
          setEmail={setEmail}
          name={name}
          setName={setName}
          OnSend={OnSend}
          error={error}
          sent={sent}
        />
      </div>
    </div>
  );
}
export default MainDiv;
