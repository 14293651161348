import MainDiv from "./MainDiv";
import Navbar from "./Navbar";
import { useState } from "react";
import { useEffect } from "react";
import { Suspense } from "react";
import { Cloud } from "./3D";
import { Cloud2 } from "./3D2";

function Home() {
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);

  useEffect(() => {
    if (!loading) {
      window.setTimeout(() => {
        setLoading2(false);
      }, 600);
      return () => {
        window.clearTimeout();
      };
    }
  }, [loading]);
  return (
    <div className="w-screen lg:h-screen absolute z-10 flex justify-center items-center overflow-y-auto xl:overflow-hidden">
      <div className="flex flex-col lg:flex-row w-screen h-screen  lg:items-center lg:justify-between ">
        <div
          className={
            loading
              ? "w-full h-full flex items-center justify-center overflow-hidden"
              : "start hidden h-full lg:flex flex flex-col items-start justify-between"
          }
        >
          <img
            alt="logo"
            className={
              loading ? "opacity-0 mt-8 ml-12 w-64 " : "start2 mt-8 ml-12 w-64 absolute"
            }
            src={"assets/newLogo.png"}
          />
          <div
            style={loading ?  { width: "100%" }:{ width: 600 } }
            className={
              loading
                ? "mr-64 w-screen h-screen hidden lg:flex items-center justify-center "
                : "hidden lg:flex items-center justify-center pl-40 "
            }
            onClick={() => setLoading(false)}
            onTouchEnd={() => setLoading(false)}
          >
            <Suspense fallback={null}>
              <Cloud />
            </Suspense>
          </div>
          <div
            className={
              loading
                ? "opacity-0 flex w-28 justify-between mb-10 ml-12"
                : "start2 flex w-28 justify-between  mb-10 ml-12 absolute bottom-1"
            }
          >
            <img alt="social icon" src="assets/fb.svg" />
            <img alt="social icon" src="assets/in.svg" />
            <img alt="social icon" src="assets/twitter.svg" />
          </div>
        </div>
        <div
          className={
            loading
              ? "hidden"
              : "w-full lg:hidden flex flex-col items-center justify-center"
          }
        >
          <img
            alt="logo"
            className="mt-10 mb-5  w-16"
            src={"assets/newLogoMark.png"}
          />
        </div>
        <div
          style={{
            backdropFilter: "blur(0px)",
          }}
          className={
            loading
              ? "lg:hidden  w-full lg:w-1/2 h-auto lg:h-full flex flex-col items-center justify-between"
              : loading2
              ? "opacity-0  w-full lg:w-1/2 h-auto lg:h-full flex flex-col items-center justify-between  "
              : "start2 w-full lg:w-1/2 h-auto lg:h-full flex flex-col items-center justify-between "
          }
        >
          <div
            className={
              loading
                ? "hidden"
                : "w-full flex justify-center items-center mt-2 absolute lg:static z-10"
            }
          >
            <Navbar />
          </div>
          <div
            className={
              loading
                ? "w-full h-screen flex items-center justify-center overflow-hidden lg:hidden"
                : "w-full h-2/5 flex items-center justify-center -my-16 lg:hidden"
            }
            onClick={() => setLoading(false)}
            onTouchEnd={() => setLoading(false)}
          >
            <Suspense fallback={null}>
              <Cloud2 />
            </Suspense>
          </div>
          <div
            className={
              loading
                ? "w-full hidden"
                : "w-full h-full  flex items-center justify-center -mt-5 "
            }
          >
            <MainDiv />
          </div>
          <div
            className={
              loading ? "hidden" : " flex w-28 justify-between mb-16  lg:hidden"
            }
          >
            <img alt="social icon" src="assets/fb.svg" />
            <img alt="social icon" src="assets/in.svg" />
            <img alt="social icon" src="assets/twitter.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
