function SecDiv({
  mood,
  setMood,
  email,
  setEmail,
  name,
  setName,
  OnSend,
  error,
  sent
}) {
  return (
    <>
      {mood === 3 ? (
        <div className="flex justify-center items-center w-full mt-2">
          <div className="flex flex-col items-center justify-center h-full w-full mr-3">
            <input
              style={{ background: "transparent" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full text-white font-thin h-1/2 rounded-3xl border p-5 border-white lg:text-2xl my-1 "
            />
            <input
              style={{ background: "transparent" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={
                error
                  ? "w-full text-white font-thin h-1/2 rounded-3xl border p-5 border-red lg:text-2xl my-1"
                  : "w-full text-white font-thin h-1/2 rounded-3xl border p-5 border-white lg:text-2xl my-1"
              }
            />
          </div>
          <a
            onClick={OnSend}
            className="border border-white rounded-full w-32 h-20 lg:w-48 lg:h-36 flex items-center justify-center text-center text-white text-2xl font-thin cursor-pointer ml-3"
          >
            {sent?"Send Again":"Send"}
          </a>{" "}
        </div>
      ) : (
        <div className=" lg:w-full lg:h-56  my-2 rounded-3xl p-3 lg:p-7 border border-black flex justify-center items-center ">
          {mood === 0 && (
            <>
              <img
                onClick={() => setMood(1)}
                alt="ball"
                src="assets/ball23D.png"
                className="w-1/2  xl:w-52 cursor-pointer  xl:mr-8 xl:h-52 ball"
              />
              <img
                onClick={() => setMood(2)}
                alt="ball"
                src="assets/ball3D.png"
                className="w-1/2  xl:w-52 cursor-pointer  xl:ml-8 xl:h-52 ball"
              />
            </>
          )}
          {mood === 1 && (
            <div className="flex justify-between items-center ">
              <div className="lg:w-1/2">
                <h2 className="text-primary text-2xl lg:text-4xl text-left mb-3">
                  The Fintech Particle
                </h2>
                <small className="text-primary text-left flex justify-start w-4/5">
                  At Cloudabill, we believe that establishing links, be it in
                  the form of connecting with the team or in welcoming
                  collaboration, strengthens our collective.
                </small>
              </div>
              <img
                onClick={() => setMood(2)}
                alt="ball"
                src="assets/ball3D.png"
                className="w-1/6 cursor-pointer mr-8"
              />
            </div>
          )}
          {mood === 2 && (
            <>
              <div>
                <h2 className="text-primary text-2xl lg:text-4xl text-left mb-3">
                  The Restaurant Particle
                </h2>
                <small className="text-primary text-left flex justify-start w-4/5">
                  At Cloudabill, we believe that establishing links, be it in
                  the form of connecting with the team or in welcoming
                  collaboration, strengthens our collective.
                </small>
              </div>
              <img
                onClick={() => setMood(1)}
                alt="ball"
                src="assets/ball23D.png"
                className="w-1/6 cursor-pointer ml-8"
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
export default SecDiv;
