import { useState } from "react";

import { LinkNav } from "./Navbar";
const getTitle = (id) => {
  return id === 0 ? (
    <>Terms and Conditions</>
  ) : id === 1 ? (
    <>Privacy Policy</>
  ) : (
    <>Cookie policy</>
  );
};
const getText = (id) => {
  if (id == 0)
    return (
      <p className="text-left text-lg font-thin">
        Website usage terms and conditions – sample template <br />
        Welcome to our website. If you continue to browse and use this website,
        you are agreeing to comply with and be bound by the following terms and
        conditions of use, which together with our privacy policy govern
        Cloudabill’s relationship with you in relation to this website. If you
        disagree with any part of these terms and conditions, please do not use
        our website. <br /> The term Cloudabill’ or ‘us’ or ‘we’ refers to the
        owner of the website whose registered office is [address]. Our company
        registration number is [company registration number and place of
        registration]. The term ‘you’ refers to the user or viewer of our
        website. <br /> <br />
        The use of this website is subject to the following terms of use: <br />{" "}
        <br />• The content of the pages of this website is for your general
        information and use only. It is subject to change without notice. <br />
        • This website uses cookies to monitor browsing preferences. If you do
        allow cookies to be used, the following personal information may be
        stored by us for use by third parties: [insert list of information].{" "}
        <br />• Neither we nor any third parties provide any warranty or
        guarantee as to the accuracy, timeliness, performance, completeness or
        suitability of the information and materials found or offered on this
        website for any particular purpose. You acknowledge that such
        information and materials may contain inaccuracies or errors and we
        expressly exclude liability for any such inaccuracies or errors to the
        fullest extent permitted by law. <br />• Your use of any information or
        materials on this website is entirely at your own risk, for which we
        shall not be liable. It shall be your own responsibility to ensure that
        any products, services or information available through this website
        meet your specific requirements. <br />• This website contains material
        which is owned by or licensed to us. This material includes, but is not
        limited to, the design, layout, look, appearance and graphics.
        Reproduction is prohibited other than in accordance with the copyright
        notice, which forms part of these terms and conditions. <br /> • All
        trademarks reproduced in this website, which are not the property of, or
        licensed to the operator, are acknowledged on the website. <br />•
        Unauthorised use of this website may give rise to a claim for damages
        and/or be a criminal offence. <br /> • From time to time, this website
        may also include links to other websites. These links are provided for
        your convenience to provide further information. They do not signify
        that we endorse the website(s). We have no responsibility for the
        content of the linked website(s). <br /> • Your use of this website and
        any dispute arising out of such use of the website is subject to the
        laws of The Arab Republic of Egypt.
      </p>
    );
  if (id === 1)
    return (
      <p className="text-left text-lg font-thin">
        This privacy policy (&quot;policy&quot;) will help you understand how
        [name] (&quot;us&quot;, &quot;we&quot;, &quot;our&quot;) uses and
        protects the data you provide to us when you visit and use [website]
        (&quot;website&quot;, &quot;service&quot;). We reserve the right to
        change this policy at any given time, of which you will be promptly
        updated. If you want to make sure that you are up to date with the
        latest changes, we advise you to frequently visit this page.
        <br />
        <br />
        <b className="text-lg font-bold"> What User Data We Collect </b>
        <br />
        <br /> When you visit the website, we may collect the following data:{" "}
        <br />
        <br />• Your IP address.
        <br /> • Your contact information and email address. <br />• Other
        information such as interests and preferences.
        <br /> • Data profile regarding your online behavior on our website. Why
        We Collect Your Data We are collecting your data for several reasons:{" "}
        <br />• To better understand your needs. <br />• To improve our services
        and products. <br />• To send you promotional emails containing the
        information we think you will find interesting. <br />• To contact you
        to fill out surveys and participate in other types of market research.{" "}
        <br />• To customize our website according to your online behavior and
        personal preferences.
        <br /> <br />
        <b className="text-lg font-bold">
          Safeguarding and Securing the Data
        </b>{" "}
        <br />
        <br />
        Cloudabill is committed to securing your data and keeping it
        confidential. [name] has done all in its power to prevent data theft,
        unauthorized access, and disclosure by implementing the latest
        technologies and software, which help us safeguard all the information
        we collect online.
      </p>
    );

  if (id === 2)
    return (
      <p className="text-left text-lg font-thin">
        <b className="text-lg font-bold">Our Cookie Policy</b>
        <br /> <br />
        Once you agree to allow our website to use cookies, you also agree to
        use the data it collects regarding your online behavior (analyze web
        traffic, web pages you spend the most time on, and websites you visit).
        <br />
        <br /> The data we collect by using cookies is used to customize our
        website to your needs. After we use the data for statistical analysis,
        the data is completely removed from our systems. Please note that
        cookies don&apos;t allow us to gain control of your computer in any way.
        They are strictly used to monitor which pages you find useful and which
        you do not so that we can provide a better experience for you.
        <br />
        <br /> If you want to disable cookies, you can do it by accessing the
        settings of your internet browser. (Provide links for cookie settings
        for major internet browsers). <br />
        <br />
        <b className="text-lg font-bold">Links to Other Websites</b>
        <br /> <br />
        Our website contains links that lead to other websites. If you click on
        these links [name] is not held responsible for your data and privacy
        protection. Visiting those websites is not governed by this privacy
        policy agreement. Make sure to read the privacy policy documentation of
        the website you go to from our website.
        <br />
        <br />{" "}
        <b className="text-lg font-bold">
          Restricting the Collection of your Personal Data
        </b>
        <br />
        <br /> At some point, you might wish to restrict the use and collection
        of your personal data. You can achieve this by doing the following:
        <br />
        <br /> When you are filling the forms on the website, make sure to check
        if there is a box which you can leave unchecked, if you don&apos;t want
        to disclose your personal information. If you have already agreed to
        share your information with us, feel free to contact us via email and we
        will be more than happy to change this for you. <br />
        <br />
        Cloudabill will not lease, sell or distribute your personal information
        to any third parties, unless we have your permission. We might do so if
        the law forces us. Your personal information will be used when we need
        to send you promotional materials if you agree to this Cookie policy
      </p>
    );
  else return <p className="text-left text-lg font-thin"></p>;
};
function Policy() {
  const [id, setId] = useState(0);
  return (
    <div className="h-screen w-full absolute z-20 flex flex-col items-center justify-start overflow-y-auto">
      <div className="bg-bg w-full flex justify-center items-center h-40 pb-5">
        <LinkNav title={"Policies"} bg />
      </div>
      <div className="w-full h-40 flex items-center justify-start   my-5 ">
        <div className="w-0 lg:w-1/4 ml-20"></div>
        <h1 className=" text-primary text-2xl lg:text-5xl font-thin text-left  ">
          {getTitle(id)}
        </h1>
      </div>
      <div className="bg-bg h-auto w-full flex flex-col lg:flex-row items-center lg:items-start justify-start">
        <div className="w-4/5 lg:w-1/4 lg:ml-20 flex justify-center">
          <div
            style={{
              boxShadow: "inset 0px 0px 80px #FFFFFF",
              background:
                "transparent linear-gradient(144deg, #FFFFFF66 0%, #031B29CC 100%) 0% 0% no-repeat padding-box",
              borderRadius: "30px",
              opacity: 1,
              backdropFilter: "blur(20px)",
              WebkitBackdropFilter: "blur(20px)",
            }}
            className="flex lg:flex-col h-28 lg:h-72 w-full lg:w-64 items-center justify-center  lg:justify-center mt-10 px-10"
          >
            <div
              onClick={() => setId(0)}
              className="w-full flex flex-col cursor-pointer items-start justify-start lg:mb-3"
            >
              <img
                className="w-7"
                src={
                  id === 0
                    ? "assets/policy/tcblack.svg"
                    : "assets/policy/tcwhite.svg"
                }
              />

              <a
                className={
                  id === 0
                    ? "cursor-pointer text-sm mt-1 font-thin text-left"
                    : "cursor-pointer text-sm mt-1 text-white font-thin text-left"
                }
              >
                Terms &<br className="lg:hidden" /> Conditions
              </a>
            </div>
            <div
              onClick={() => setId(1)}
              className="w-full flex flex-col cursor-pointer items-center justify-center lg:justify-start lg:items-start  lg:mb-3"
            >
              <img
                className="w-7"
                src={
                  id === 1
                    ? "assets/Shield Done.png"
                    : "assets/policy/sd.svg"
                }
              />
              <a
                className={
                  id === 1
                    ? "cursor-pointer text-sm mt-1 font-thin text-center lg:text-left"
                    : "cursor-pointer text-sm mt-1 text-white font-thin text-center lg:text-left"
                }
              >
                Privacy
                <br className="lg:hidden" /> Policy
              </a>
            </div>
            <div
              onClick={() => setId(2)}
              className="w-full flex flex-col cursor-pointer items-end justify-end lg:justify-start lg:items-start lg:mb-3"
            >
              <img
                className="w-7"
                src={
                  id === 2
                    ? "assets/policy/cblack.svg"
                    : "assets/policy/ppwhite.svg"
                }
              />
              <a
                className={
                  id === 2
                    ? "cursor-pointer text-sm mt-1 font-thin text-right lg:text-left"
                    : "cursor-pointer text-sm mt-1 text-white font-thin text-right lg:text-left "
                }
              >
                Cookie
                <br className="lg:hidden" /> policy
              </a>
            </div>
          </div>
        </div>
        <div className="w-4/5 lg:w-1/2 mt-10 pb-10">{getText(id)}</div>
      </div>
    </div>
  );
}
export default Policy;
